import Vue from 'vue'
import App from './App.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationCircle, faSave, faTimes, faAngleRight, faCheck, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faExclamationCircle, faSave, faTimes, faAngleRight, faCheck, faLevelUpAlt)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

Vue.component( 'netsuite-header', require('./components/NetSuiteHeader.vue').default );

Vue.component( 'scan-view', require('./components/ScanView.vue').default );
Vue.component( 'line-items', require('./components/LineItems.vue').default );
Vue.component( 'tabular', require('./components/Tabular.vue').default );
Vue.component( 'scanner', require('./components/Scanner.vue').default );
Vue.component( 'nav-item', require('./components/NavItem.vue').default );
Vue.component( 'nav-button', require('./components/NavButton.vue').default );
Vue.component( 'input-quantity', require('./components/InputQuantity.vue').default );
Vue.component( 'popup', require('./components/popup.vue').default );
Vue.component( 'sort-icon', require('./components/sorticon.vue').default );

Vue.component( 'embroidery-items', require('./components/embroidery/EmbroideryItems.vue').default );
Vue.component( 'embroidery-controller', require('./components/embroidery/EmbroideryController.vue').default );
Vue.component( 'embroidery-details', require('./components/embroidery/EmbroideryDetails.vue').default );


Vue.component( 'po-items', require('./components/purchaseorder/PoItems.vue').default );
Vue.component( 'po-details', require('./components/purchaseorder/PoDetails.vue').default );
Vue.component( 'poreceipt-controller', require('./components/purchaseorder/PoreceiptController.vue').default );

import vSelect from 'vue-select'
import "vue-select/src/scss/vue-select.scss";



Vue.component('v-select', vSelect)

new Vue({
  render: h => h(App),
}).$mount('#app')
